import type { OgType, Seo } from "~/types/seo";

/**
 * Sets meta tags with `useSeoMeta` and returns the wep page attributes
 * for schema that should be used in all pages.
 */

export function useSeo(data: Seo, translationParams?: TranslationParams, addLocaleHead: boolean = true) {
  const { siteUrl } = useRuntimeConfig().public;
  const { localeProperties, locale } = useI18n();
  const { getLocalizedRoute } = useLocalizedRoute();
  const route = useRoute();

  // We need to remove the trailing / otherwise it will be duplicated in the schema.org
  const sanitizedIndexPath = getLocalizedRoute(locale.value, "index").path === "/" ? "" : getLocalizedRoute(locale.value, "index").path;

  // Set meta tags
  useSeoMeta({
    robots: () => data.robots?.reverse().join(", "),
    title: () => data.title!, // mapSeo already provides a default value
    description: () => data?.description ?? "",
    ogTitle: () => data?.title ?? "",
    ogDescription: () => data.description,
    ogImageUrl: () => data.openGraph?.image?.secureUrl ?? imagePlacholder.blog,
    ogImageSecureUrl: () => data.openGraph?.image?.secureUrl ?? imagePlacholder.blog,
    ogImageAlt: () => data.openGraph?.image ? data.title as string : "Grape Law Firm",
    ogType: () => data.openGraph?.type as OgType || "website",
    twitterCard: () => "summary_large_image",
    twitterTitle: () => data.title,
    twitterDescription: () => data.description,
    twitterImage: () => data.openGraph?.image?.secureUrl ?? imagePlacholder.blog,
    twitterImageAlt: () => data.title as string,
  });

  // Set head attributes that require localization
  const i18nHead = useLocaleHead({
    key: "hid",
  });

  const getFilteredLinks = (links: any[]) => {
    if (translationParams) {
      const comingAvailableLocales = Object.keys(translationParams);

      const filteredLinks = links.filter((link: any) => {
        if (link.rel !== "alternate")
          return true;

        if (link.hreflang === "x-default")
          return false;

        return comingAvailableLocales.some((locale) => {
          return link.hreflang === locale || link.hreflang.startsWith(`${locale}-`);
        });
      });

      return filteredLinks;
    }

    return links;
  };

  if (i18nHead.value.link) {
    i18nHead.value.link = getFilteredLinks(i18nHead.value.link);
  }

  // Check if x-default is present and create it if it's missing
  const hasXDefault = i18nHead.value.link?.some((link: any) => link.hreflang === "x-default");
  const xDefaultLink = !hasXDefault
    ? {
        id: "i18n-xd",
        rel: "alternate",
        href: `${siteUrl}${route.path}`,
        hreflang: "x-default",
      }
    : null;

  useHead({
    htmlAttrs: {
      lang: i18nHead.value.htmlAttrs!.lang,
    },
    link: [
      ...(xDefaultLink ? [xDefaultLink] : []),
      ...(addLocaleHead ? (i18nHead.value.link || []) : []),
    ],
    meta: [...(i18nHead.value.meta || [])],
  });

  // Return the web page attributes for schema.org
  return {
    // id and url are provided by the schema.org plugin
    schemaWebPage: {
      name: data.title,
      datePublished: data.openGraph?.articleMeta?.publishedTime ?? undefined,
      dateModified: data.openGraph?.updatedTime || data.openGraph?.articleMeta?.modifiedTime,
      about: { "@id": `${siteUrl}${sanitizedIndexPath}/#organization` },
      isPartOf: { "@id": `${siteUrl}${sanitizedIndexPath}/#website` },
      inLanguage: localeProperties.value.iso,
    },
  };
}

export type TranslationParams = Record<string, TranslationData>;

interface TranslationData {
  [key: string]: string | undefined;
}
